// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuellt-js": () => import("./../src/pages/aktuellt.js" /* webpackChunkName: "component---src-pages-aktuellt-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mina-produkter-js": () => import("./../src/pages/mina-produkter.js" /* webpackChunkName: "component---src-pages-mina-produkter-js" */),
  "component---src-pages-om-mig-js": () => import("./../src/pages/om-mig.js" /* webpackChunkName: "component---src-pages-om-mig-js" */),
  "component---src-pages-sagoberattare-js": () => import("./../src/pages/sagoberättare.js" /* webpackChunkName: "component---src-pages-sagoberattare-js" */)
}

